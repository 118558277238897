import React from "react";
import Layout from "../layouts/Layout";
import styled from "styled-components";
import MetodoPiuVeloce from "../sections/MetodoPiuVeloce";
import { StaticImage } from "gatsby-plugin-image";
/* import DefaultButton from "../components/buttons/DefaultButton"; */
import { Link } from 'gatsby'
import { v4 as uuidv4 } from "uuid";

const StyledStaticPage = styled.div`
  .static-page {
    &__title {
      text-align: center;
      @media (min-width: 992px) {
        padding-top: 200px;
      }
    }

    &__slug {
      @media(min-width:992px) {
        max-width: 50%;
      }
      a {
        text-decoration: none;
        color: #000;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__header {
      background-color: #fff;

      @media (min-width: 992px) {
        background: -webkit-linear-gradient(
          273deg,
          rgba(242, 242, 242, 0.7) 0%,
          rgba(206, 212, 216, 0.7) 65%,
          rgba(206, 212, 216, 0.698) 100%
        );
        background: -o-linear-gradient(
          273deg,
          rgba(242, 242, 242, 0.7) 0%,
          rgba(206, 212, 216, 0.7) 65%,
          rgba(206, 212, 216, 0.698) 100%
        );
        background: -ms-linear-gradient(
          273deg,
          rgba(242, 242, 242, 0.7) 0%,
          rgba(206, 212, 216, 0.7) 65%,
          rgba(206, 212, 216, 0.698) 100%
        );
        background: -moz-linear-gradient(
          273deg,
          rgba(242, 242, 242, 0.7) 0%,
          rgba(206, 212, 216, 0.7) 65%,
          rgba(206, 212, 216, 0.698) 100%
        );
        background: linear-gradient(
          177deg,
          rgba(242, 242, 242, 0.7) 0%,
          rgba(206, 212, 216, 0.7) 65%,
          rgba(206, 212, 216, 0.698) 100%
        );
        height: 300px;
      }


      &__description {
        text-align: center;

        @media (min-width: 992px) {
          text-align: left;
          margin-left: 20px;
          max-width: 45%;
        }

        @media (min-width: 992px) {
          height: 300px;
        }
      }

      .intro {
        color: #527191;
      }

      .title {
        padding-left: 3em;
      }
    }
  }

  .wrapper {
    height: 100%;
    max-width: 1440px;
    justify-content: center;

    @media (min-width: 768px) {
      justify-content: space-between;
    }
    &__image-description {
      height: 100%;
      width: 100%;
      align-content: center;
      align-items: center;
    }
  }

  .form-multi-step {
    position: absolute;
    right: 0;
    top: 50px;
    z-index: 1;
  }

  .section {
    display: flex;
    align-items: center;
    @media (max-width: 992px) {
      flex-direction: column;
    }
    &__title {
      color: #e7c700;
      font-size: 30px;
      min-width: 400px;
      align-self: flex-start;
      text-align: left;
      padding-top: 1em;
    }
  }
`;

export default function faq({ className }) {
  return (
    <Layout 
      helmetTitle="FAQ: domande e risposte su Cessione del Quinto - Prestitifaidate"
      helmetDescription="Leggi le nostre FAQ: tutte le domande e le risposte per scoprire di più su Cessione del Quinto, Prestiti e Mutui Casa. Utilizza anche la chat per saperne di più!"
    >
      <StyledStaticPage id="page-content">
        <div className="static-page__header">
          <div className="wrapper container d-flex align-items-center position-relative">
            <div className="d-flex align-items-center flex-column-reverse flex-lg-row wrapper__image-description">
              <StaticImage
                src="../assets/images/testatina-faq.png"
                className={className}
                alt="FAQ"
                placeholder="blurred"
                layout="fixed"
                width={161}
                height={150}
                objectFit="contain"
                quality={75}
              />
              <div className="d-flex flex-column static-page__header__description justify-content-center pb-3 pb-lg-0">
                <span className="fw-bold fs-1">FAQ</span>
                <span className="intro">
                  Tutte le risposte alle tue domande
                </span>
              </div>
            </div>
            <form
              id="form-multi-step"
              action={process.env.GATSBY_FORM_ACTION}
              method="POST"
              className="form-multi-step form-multi-step d-none d-lg-block"
            ></form>
          </div>
        </div>
        <div id="page-content" className="container mx-auto">
          <div className="static-page__slug mb-5 mt-4 fs-5">
          {" "}
            <Link to="/" className="fw-bold">
              HOME
            </Link>{" "}
            &gt; <Link to="/faq/">FAQ</Link>
          </div>
          <h1 className="static-page__title mb-5 display-5 fw-bold">FAQ: Domande e Risposte</h1>
          <div className="static-page__content">
            <section className="section">
              <span className="section__title">
                Abbiamo tutte <br />
                le risposte!
              </span>
              <div className="section__content">
                <div className="accordion accordion-flush" id="accordionFaq">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button shadow-none"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        DI COSA CI OCCUPIAMO?
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionFaq"
                    >
                      <div className="accordion-body">
                        Siamo un Intermediario del Credito iscritto regolarmente
                        nell’Elenco delle Agenzie in Attività Finanziaria tenuto
                        dall’Organismo di Vigilanza (OAM) con il n. A107. Il
                        servizio consiste nella promozione e conclusione di
                        contratti relativi alla concessione di finanziamenti
                        sotto qualsiasi, su mandato diretto di intermediari
                        finanziari previsti dal titolo V del TUB.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button shadow-none collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        CHI PUÒ RICHIEDERE UNA CESSIONE DEL QUINTO?
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionFaq"
                    >
                      <div className="accordion-body">
                        La Cessione del Quinto dello Stipendio è un particolare
                        tipo di prestito personale disciplinato dal D.P.R.
                        180/50 ed ha la caratteristica fondamentale che la rata
                        viene pagata ogni mese direttamente dal datore di
                        lavoro, (Ente Pubblico, Ministero, Impresa Privata) o
                        dall’Ente Previdenziale (INPS, ex INPDAP, ENASARCO etc.)
                        del richiedente. In pratica, chi ha richiesto un
                        prestito di questo tipo, riceverà una busta paga o
                        cedolino pensionistico decurtata del 20%. Hanno diritto
                        a richiedere questo tipo di prestito tutti i lavoratori
                        dipendenti pubblici, privati, che i pensionati, e non
                        hanno nessun obbligo di dichiarare per quali fini
                        richiedono questa somma, non essendo un prestito
                        finalizzato all’acquisto di un determinato bene. La
                        durata massima consentita è di 120 mesi, sempre che il
                        lavoratore abbia età ed anzianità tali per cui non debba
                        andare in pensione prima di questo termine. Il valore
                        massimo del finanziamento è determinato dalla singola
                        rata, che naturalmente non potrà essere superiore al
                        quinto dello stipendio. Ottenere la cessione del quinto
                        è abbastanza semplice e non è necessaria alcuna
                        fidejussione o garanzia: si tratta infatti di un
                        prestito a firma singola in cui la banca è garantita
                        dallo stipendio del dipendente. Per una maggiore
                        tranquillità di entrambe le parti il legislatore ha
                        previsto che questo prestito debba essere accompagnato
                        obbligatoriamente da un’assicurazione vita e rischio
                        impiego. In questo modo non ci saranno pendenze gravanti
                        ne sugli eredi in caso di morte, ne sullo stesso
                        richiedente in caso di licenziamento. Nel caso in cui
                        invece sia il dipendente a dare le dimissioni, il datore
                        di lavoro è tenuto a conservare tutte le somme a lui
                        dovute, ivi comprese la liquidazione maturata, la quota
                        della tredicesima, le ferie non godute, etc. e a
                        versarle direttamente alla banca che ha erogato il
                        prestito.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button shadow-none collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        QUANTO TEMPO È NECESSARIO PER AVERE LA SOMMA RICHIESTA?
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionFaq"
                    >
                      <div className="accordion-body">
                        All’atto dell’istruzione della pratica che, ricevuto il
                        benestare da parte dell’Amministrazione di appartenenza
                        si eroga il finanziamento entro 10 giorni lavorativi.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFour">
                      <button
                        className="accordion-button shadow-none collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        QUALI SONO I COSTI DA SOSTENERE PER ACCEDERE A QUESTO
                        TIPO DI FINANZIAMENTO?
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFour"
                      data-bs-parent="#accordionFaq"
                    >
                      <div className="accordion-body">
                        Il costo del finanziamento viene corrisposto dal cedente
                        in un’unica soluzione e trattenuto dal capitale lordo
                        del finanziamento. L’importo è dovuto per commissioni ed
                        interessi bancari, costi di istruttoria e di
                        intermediazione, rimborso spese di notifica e postali,
                        polizza assicurativa contro il rischio della perdita del
                        posto di lavoro e contro la perdita della vita.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFive">
                      <button
                        className="accordion-button shadow-none collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        COME AVVIENE IL RIMBORSO DEL FINANZIAMENTO?
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFive"
                      data-bs-parent="#accordionFaq"
                    >
                      <div className="accordion-body">
                        Il rimborso del finanziamento avviene tramite rate
                        mensili costanti, trattenute dall’amministrazione di
                        appartenenza direttamente dalla busta paga o cedolino
                        pensione, e versate mensilmente alla società
                        finanziaria, fino alla concorrenza del capitale lordo
                        del finanziamento.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSix">
                      <button
                        className="accordion-button shadow-none collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSix"
                        aria-expanded="false"
                        aria-controls="collapseSix"
                      >
                        È POSSIBILE SALDARE IL DEBITO ANTICIPATAMENTE?
                      </button>
                    </h2>
                    <div
                      id="collapseSix"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingSix"
                      data-bs-parent="#accordionFaq"
                    >
                      <div className="accordion-body">
                        Si è possibile saldare il debito anticipatamente
                        beneficiando dell’abbuono interessi non maturati.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSeven">
                      <button
                        className="accordion-button shadow-none collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSeven"
                        aria-expanded="false"
                        aria-controls="collapseSeven"
                      >
                        PERCHÉ BISOGNA SOTTOSCRIVERE UNA POLIZZA ASSICURATIVA?
                      </button>
                    </h2>
                    <div
                      id="collapseSeven"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingSeven"
                      data-bs-parent="#accordionFaq"
                    >
                      <div className="accordion-body">
                        La polizza assicurativa è prevista dalla legge che
                        disciplina questa forma di finanziamento (Legge 180/50 e
                        Legge 14 maggio 2005, n. 80).
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingEight">
                      <button
                        className="accordion-button shadow-none collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseEight"
                        aria-expanded="false"
                        aria-controls="collapseEight"
                      >
                        LA RATA COSÌ COME IL TASSO DI INTERESSE RIMANE INVARIATA
                        PER TUTTO IL PERIODO DEL FINANZIAMENTO?
                      </button>
                    </h2>
                    <div
                      id="collapseEight"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingEight"
                      data-bs-parent="#accordionFaq"
                    >
                      <div className="accordion-body">
                        Si la rata è fissa per tutta la durata del finanziamento
                        che può essere da 24 a 120 mesi, cosi come il tasso
                        rimane fisso per tutta il periodo di ammortamento del
                        prestito.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingNine">
                      <button
                        className="accordion-button shadow-none collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseNine"
                        aria-expanded="false"
                        aria-controls="collapseNine"
                      >
                        COSA SUCCEDE SE SI HA GIÀ UNA CESSIONE DEL QUINTO IN
                        CORSO?
                      </button>
                    </h2>
                    <div
                      id="collapseNine"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingNine"
                      data-bs-parent="#accordionFaq"
                    >
                      <div className="accordion-body">
                        La cessione in corso dovrà essere obbligatoriamente
                        estinta. Il residuo debito della stessa viene detratto
                        dal netto ricavo della nuova operazione per essere
                        versato a favore dell’ente titolare del precedente
                        prestito per la sua estinzione.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTen">
                      <button
                        className="accordion-button shadow-none collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTen"
                        aria-expanded="false"
                        aria-controls="collapseTen"
                      >
                        QUALI DOCUMENTI OCCORRE PRESENTARE PER RICHIEDERE UN
                        PRESTITO TRAMITE CESSIONE DEL QUINTO?
                      </button>
                    </h2>
                    <div
                      id="collapseTen"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTen"
                      data-bs-parent="#accordionFaq"
                    >
                      <div className="accordion-body">
                        <ul>
                          <li key={uuidv4()}>
                            Nel caso di dipendenti: Il certificato di stipendio
                            sottoscritto dall’amministrazione, l’ultima busta
                            paga, l’ultimo CUD, un documento d’identità, e la
                            tessera sanitaria.
                          </li>
                          <li key={uuidv4()}>
                            Nel caso di pensionati: Il certificato di quota
                            cedibile sottoscritta dall’Ente Previdenziale,
                            l’ultimo cedolino pensione, l’ultimo CUD, un
                            documento d’identità, e la tessera sanitaria.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingEleven">
                      <button
                        className="accordion-button shadow-none collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseEleven"
                        aria-expanded="false"
                        aria-controls="collapseEleven"
                      >
                        CHE DIFFERENZA C’È TRA LA CESSIONE DEL QUINTO E IL
                        CLASSICO PRESTITO CHE POSSO RICHIEDERE IN BANCA?
                      </button>
                    </h2>
                    <div
                      id="collapseEleven"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingEleven"
                      data-bs-parent="#accordionFaq"
                    >
                      <div className="accordion-body">
                        Non sono richieste garanzie ipotecarie, eventuali
                        disguidi finanziari passati a carico non impediscono il
                        perfezionamento del finanziamento. La sola garanzia
                        richiesta è rappresentata da un rapporto di lavoro a
                        tempo indeterminato presso aziende pubbliche e private
                        che presentino requisiti di solidità e affidabilità o
                        dalla pensione percepita dall’Ente Previdenziale.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwelve">
                      <button
                        className="accordion-button shadow-none collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwelve"
                        aria-expanded="false"
                        aria-controls="collapseTwelve"
                      >
                        COSA SUCCEDE NEL CASO RIMANESSI SENZA LAVORO?
                      </button>
                    </h2>
                    <div
                      id="collapseTwelve"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwelve"
                      data-bs-parent="#accordionFaq"
                    >
                      <div className="accordion-body">
                        In tal caso, laddove il TFR accantonato non fosse
                        sufficiente a coprire il debito residuo e il Cliente non
                        riuscisse a rimborsare la parte eccedente, provvederà in
                        tal senso l’assicurazione convenzionata con l’ente
                        erogatore.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThirteen">
                      <button
                        className="accordion-button shadow-none collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThirteen"
                        aria-expanded="false"
                        aria-controls="collapseThirteen"
                      >
                        QUAL È LA DIFFERENZA PRINCIPALE TRA PRESTITO PERSONALE E
                        CESSIONE DEL QUINTO?
                      </button>
                    </h2>
                    <div
                      id="collapseThirteen"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThirteen"
                      data-bs-parent="#accordionFaq"
                    >
                      <div className="accordion-body">
                        Nel Prestito Personale la rata viene pagata alla banca o
                        alla finanziaria tramite bollettino postale o addebito
                        sul conto corrente (RID), mentre nella Cessione del
                        Quinto la rata viene detratta dallo stipendio o dalla
                        pensione. Quindi è l’azienda o l’amministrazione presso
                        cui si è dipendenti o pensionati a pagare. Questo rende
                        la cessione del quinto più comoda per il cliente che non
                        dovrà preoccuparsi di pagare le future rate del
                        finanziamento erogato.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFourteen">
                      <button
                        className="accordion-button shadow-none collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFourteen"
                        aria-expanded="false"
                        aria-controls="collapseFourteen"
                      >
                        QUALI SONO GLI IMPORTI FINANZIABILI IN BASE ALLA
                        TIPOLOGIA (ES. CESSIONE DEL QUINTO, PRESTITO PERSONALE E
                        FINANZIAMENTO PERSONALE)?
                      </button>
                    </h2>
                    <div
                      id="collapseFourteen"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFourteen"
                      data-bs-parent="#accordionFaq"
                    >
                      <div className="accordion-body">
                        Si possono chiedere importi fino a 75.000 € con un piano
                        di rimborso da 12 a 120 mesi.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFifteen">
                      <button
                        className="accordion-button shadow-none collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFifteen"
                        aria-expanded="false"
                        aria-controls="collapseFifteen"
                      >
                        SONO STRANIERO. POSSO COMUNQUE RICHIEDERE UN PRESTITO?
                      </button>
                    </h2>
                    <div
                      id="collapseFifteen"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFifteen"
                      data-bs-parent="#accordionFaq"
                    >
                      <div className="accordion-body">
                        Per richiedere un prestito non è necessario avere
                        cittadinanza italiana, ma è sufficiente avere la
                        residenza in Italia.
                      </div>
                    </div>
                  </div>
                </div>

                {/* <DefaultButton
                  title="RICHIEDI IL TUO PRESTITO"
                  className="mt-5 btn-outline-dark d-block mx-auto"
                  url="#"
                /> */}
              </div>
            </section>
          </div>
        </div>
      </StyledStaticPage>
      <MetodoPiuVeloce className="mt-5" />
    </Layout>
  );
}
